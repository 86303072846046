import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { saveemployeetoproject, saveitem, updateItem } from '../../utils/firebasefunctions';
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { RotatingLines } from 'react-loader-spinner'
import { HiOutlineArrowDown } from "react-icons/hi2";
import { arrayUnion } from 'firebase/firestore';
import userlogo from '../images/user.png'
import { IoIosCloseCircle } from 'react-icons/io';
import { IoCheckmarkCircle } from "react-icons/io5";
import { FaCircleMinus } from 'react-icons/fa6';
import ProjectPopUp from '../components/ProjectPopUp';
import EmployeePopup from '../components/EmployeePopup';
import { FaDollarSign } from 'react-icons/fa';

// import Section2 from '../components/Homesection/section2'
function AddEmployeeToProject() {
    const [{ user, employees, clients }, dispatch] = useStateValue();
    const [taskdescription, settaskdescription] = useState("");
    const [taskname, settaskname] = useState("");
    const [selectedemployee, setselectedemployee] = useState([]);
    const [selectedproject, setselectedproject] = useState("");
    const [duedate, setduedate] = useState(new Date());
    const [startdate, setstartdate] = useState(new Date());
    const [employeepopup, setemployeepopup] = useState(false);
    const [projectpopup, setprojectpopup] = useState(false);
    const [loading, setloading] = useState(false);







    const assignproject = async () => {
        const assign = employees.filter((x => x.projectassigned?.find(data => data.project == x.id) != undefined))

        if (employees.filter((x => x.selected)).length < 1) {
            toast.warn("Please select an employee to assign to project.")
        } else if (selectedproject == "") {
            toast.warn("Please select a project to assign an employee to.")
        }
        else if (assign.lenght > 0) {
            assign.map((item) => {
                toast.warn(`${item.firstname} ${item.lastname} is already assigned to ${selectedproject.title} `)

            })
        }
        else {
            setloading(true)

            let getemployee = employees.filter((x => x.selected))

            getemployee.map(async (item, index) => {
                // if (item.projectassigned?.find(data => data.project == item.id) != undefined) {
                //     toast.warn(`${item.firstname} ${item.lastname} is already assigned to ${selectedproject.title} `)

                // }
                // else {
                    let data = {
                        projectassigned: arrayUnion({
                            project: selectedproject.id,
                            hourlywage: item.projectwage
                        }),
                    }
                await updateItem("users", item.id, data).then(async (response) => {

                    let dataarray = {
                        employees: arrayUnion(item.id)
                    }

                    await updateItem("users", selectedproject.id, dataarray).then(async (response) => {
                        let data = []

                        if (item.projectassigned == undefined) {
                            data.push({
                                project: selectedproject.id,
                                hourlywage: item.projectwage
                             })
                            item["projectassigned"] = data
                        } else {
                            data = [...item.projectassigned]
                            data.push({
                                 project: selectedproject.id,
                                 hourlywage: item.projectwage
                             })
                            item.projectassigned = data

                        }

                        let employeedata = [...employees]
                        const employeeindex = employeedata.findIndex(item => item.id === item.id);
                        employeedata[employeeindex]["projectassigned"] = data

                        dispatch({
                            type: actionType.UPDATEEMPLOYEEDATA,
                            payload: employeedata
                        })

                        toast.success(item.firstname + " " + item.lastname +  " assigned successfully to " +  selectedproject.title)

if(index   == getemployee.length - 1){


                        setselectedemployee([])
                        let employeesdata = [...employees]
                        for (const key in employeesdata){
                            employeesdata[key].selected = false
                        }


                        dispatch({
                            type: actionType.STORE_EMPLOYEES,
                            payload: employeesdata
                        })
                        setselectedproject("")
                        setloading(false)
                    }

                    }).catch((err) => {
                        alert("updating error " + err)
                        setloading(false)

                    })
                }).catch((err) => {
                    toast.warn(err)
                    console.log(err)
                    setloading(false)
                })
                // }
            })




        }
    }

    const createAssignedProjectsSet = () => {
        const assignedProjects = new Set();
        let getemployee = employees.filter((x => x.selected))

        getemployee.forEach(employee => {
            employee?.projectassigned?.forEach(assignment => {
                assignedProjects.add(assignment.project);
            });
        });

        return assignedProjects;
    };

    const handlehourlywage = (event, data) => {
        let dataset = [...employees]
        const designationindex = dataset.findIndex(item => item.id === data.id);
        dataset[designationindex].projectwage = event.target.value
        dispatch({
            type: actionType.STORE_EMPLOYEES,
            payload: dataset
        })
      }
    const assignedProjects = createAssignedProjectsSet();

    return (







        <div className='w-[100%]     flex flex-col items-center  mt-[70px] md:mt-[0px] '>
            <p className='text-[22px] text-textcolor PoppinsRegular font-semibold self-start pb-[30px]'>Assign Project</p>
            <div className='w-[100%]  px-[20px] py-[30px]  border border-gray-300 mx-auto  bg-cardOverlay rounded-xl  drop-shadow-lg '>
                <div className='flex flex-col md:flex-row items-center justify-between w-full'>
                    <div className='w-full md:w-[45%]  '>
                        <div

                            className=' w-full flex flex-col items-center ' >
                            {employees.filter((x => x.selected)).length > 0 ?
                                <div className='flex flex-row' >
                                    {employees.filter((x => x.selected)).map((item, ind) => {
                                        return (
                                            <img
                                                style={{
                                                    marginLeft: employees.filter((x => x.selected)).length > 1 ? -50 : 0,
                                                }}
                                                src={item.profilepicture ? item.profilepicture : userlogo} className='w-[100px] h-[100px] rounded-full object-cover' />
                                        )
                                    })}
                                </div>
                                :
                                <img

                                    src={userlogo} className='w-[100px] h-[100px] rounded-full object-cover' />
                            }
                            <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1 w-full text-left mt-[20px]'>Select Employee</p>

<div className='flex relative  w-full'>
                            <div
                                onClick={() => {
                                    setemployeepopup(!employeepopup)}}
                                className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-[10px] rounded-md border border-themeColor items-center flex flex-wrap " >
                                {employees?.length == 0 || employees.find(data => data.selected == true) == undefined ? "Select Employee" : employees.filter(function (data) { return data.selected == true }).map(function (data) {
                                    return <div
                                        onClick={() => {

                                            let dataset = [...employees]
                                            const designationindex = dataset.findIndex(item => item.id === data.id);
                                            dataset[designationindex].selected = false
                                            dispatch({
                                                type: actionType.STORE_EMPLOYEES,
                                                payload: dataset
                                            })
                                        }}
                                        className='bg-themeColor px-[5px] py-[1px] mr-[5px] rounded-md flex flex-row items-center mb-[2px]'><p className='text-white '>{data.firstname}</p>
                                        <IoIosCloseCircle
                                            onClick={(id) => {
                                                console.log(id)
                                            }}
                                            className=" text-white ml-[3px]  w-[15px] h-[15px] hover:cursor-pointer"
                                        />
                                    </div>
                                })}







                            </div>
                            <EmployeePopup
                                visible ={employeepopup}
                                data = {employees}
                                activateoutsidecontainerclick = {()=>{
                                    setemployeepopup(false);
                                }}
                                onClick={(item, index) => {
                                  let data = [...employees]
                                  data[index].selected = !data[index].selected
                                  data[index]["projectwage"] = 0
                                  dispatch({
                                      type: actionType.STORE_EMPLOYEES,
                                      payload: data
                                  })
                                  setselectedemployee(data.filter(obj => obj.selected))
                              }}
                              />
                              </div>

                        </div>
                    </div>


                    {/*
            <HiOutlineArrowDown

className="text-lighttextGray/80 w-[60px] h-[100px] "
/> */}
                    <div className='w-full md:w-[45%] mt-[40px] md:mt-[0px] '>
                        <div

                            className='w-full flex flex-col items-center ' >

                            <img src={selectedproject.logo ? selectedproject.logo : userlogo} className='w-[100px] h-[100px] rounded-full object-cover ' />

                            <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1 w-full text-left mt-[20px]'>Select Project</p>
                            <div className='flex relative  w-full'>
                            <div
                                onClick={() =>{ employees.filter((x => x.selected)).length < 1 ? toast.warn('Select Employee First') : setprojectpopup(!projectpopup)}}
                                className={`relative px-4 ${selectedproject?.title == undefined ? " text-gray-500 " : "text-textcolor"} text-sm font-medium PoppinsRegular w-full py-3 rounded-md border border-themeColor items-center flex `}>{selectedproject?.title == undefined ? "Click to select" : selectedproject?.title}

                            </div>
                            <ProjectPopUp
                                        visible ={projectpopup}
                                        data = {clients}
                                        assignedProjects = {assignedProjects}
                                        activateoutsidecontainerclick = {()=>{
                                            setprojectpopup(false);
                                        }}
                                        onClickincorrect={() => { setselectedproject([]) }}
                                                   onClickcorrect={(item) => {
                                                        setselectedproject(item)
                                                        // setselectedemployee([])
                                                    }}
                                      />
                            </div>
                        </div>
                    </div>

                </div>


<div className=''>
                {employees.filter(function (data) { return data.selected == true }).map(function (data, index) {
                                        return(
<div className={`w-full flex flex-row items-center justify-center ${index == 0 ? "mt-[50px]" : null}`}>
    <div className='mr-[20px]'>
<FaCircleMinus
onClick={()=>{
    let dataset = [...employees]
    const designationindex = dataset.findIndex(item => item.id === data.id);
    dataset[designationindex].selected = false
    dataset[designationindex].projectwage = 0

    dispatch({
        type: actionType.STORE_EMPLOYEES,
        payload: dataset
    })
}}
className=" text-red-500 w-[20px] h-[20px] hover:cursor-pointer mt-[20px]"
/>
</div>
<div className='w-full' >
<p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Employee</p>
<div className=' input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex' >{`${data.firstname} ${data.lastname}`}</div>
</div>
<div className='w-full md:w-[30%] ml-[20px] ' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Hourly Wage</p>
                                <div className='w-full py-[6px] rounded-xl border border-themeColor items-center flex flex-row'>
            <div className='pl-5'>
              <FaDollarSign />
            </div>
            <input type="number"
              inputMode='numeric'
              value={data.projectwage}
              placeholder={'Salary'}
              onChange={(event)=>{handlehourlywage(event, data)}}
              className="p-[0px] text-textcolor text-sm font-medium PoppinsRegular  w-[95%] lg:w-[97%]  px-[17px] lg:px-4 border-none items-center flex"
            />

            <p className='text-textcolor  text-lg pr-2 '>/hr</p>
          </div>
                            </div>
</div>
                                        )})}
                                        </div>

                <div className='py-[20px] w-full'>
                    {loading ?

                        <div
                        className=" py-4  mt-6 w-full flex justify-center  "
                    >
                        <RotatingLines
                            visible={true}
                            height="25"
                            width="25"
                            color="grey"
                            strokeColor="#218fdb"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                        :

                        <button
                            onClick={() => {
                                assignproject()
                            }}
                            type='submit' className='text-center py-4 rounded-md  bg-themeColor hover:bg-themeColor/80 w-full  mx-auto text-white text-sm font-semibold PoppinsRegular uppercase '>
                            Assign
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}

export default AddEmployeeToProject;