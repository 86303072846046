import React, { useEffect, useRef, useState } from 'react'
import './index.css'

import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { gethighestWmployeeID, saveuserdata, updateItem } from '../../utils/firebasefunctions';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import DatePicker from "react-datepicker";
import { IoIosCloseCircle } from 'react-icons/io';
import "react-datepicker/dist/react-datepicker.css";
import { FaDollarSign } from 'react-icons/fa';
import { ToastContainer, toast } from "react-toastify";
import { FaUser } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { FaCircleMinus } from 'react-icons/fa6';
import {
    getDownloadURL,
    ref,
    uploadBytes,
    uploadBytesResumable
} from "firebase/storage";
import { storage, app } from '../../firebase.config'
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { RotatingLines } from 'react-loader-spinner'
import ProjectPopUp from '../components/ProjectPopUp';
import userlogo from '../images/user.png'
import { arrayUnion } from 'firebase/firestore';

const CreateEmployee = (props) => {
    const [{ user,clients }, dispatch] = useStateValue();
    const [email, setemail] = useState("");
    const [fixedprice, setfixedprice] = useState(0);
    const [password, setpassword] = useState("");
    const [firstname, setfirstname] = useState("");
    const [lastname, setlastname] = useState("");
    const [picturedata, setpicturedata] = useState("");
    const [picture, setpicture] = useState("");
    const [confirmpassword, setconfirmpassword] = useState("");
    const [phonenumber, setphonenumber] = useState("");
    const [usertype, setusertype] = useState("Employee")
    const [designation, setdesignation] = useState("General")
    const [designationtypepopup, setdesignationtypepopup] = useState(false)
    const [usertypepopup, setusertypepopup] = useState(false)
    const [passwordvisible, setpasswordvisible] = useState(false)
    const [loader, setloader] = useState(false)
    const [confirmpasswordvisible, setconfirmpasswordvisible] = useState(false)
    const [usertypelist, setusertypelist] = useState([]);
    const [projectpopup, setprojectpopup] = useState(false);
    const [selectedproject, setselectedproject] = useState([]);

const usertypepopupref = useRef(null)
const designationtypepopupref = useRef(null)

    useEffect(() => {
        if (user?.id) {

            initialiseusertypes()
        }
    }, [user])

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (usertypepopupref.current && !usertypepopupref.current.contains(event.target)) {
            setusertypepopup(false);
          }

          if (designationtypepopupref.current && !designationtypepopupref.current.contains(event.target)) {
            setdesignationtypepopup(false);
          }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [usertypepopupref, designationtypepopupref]);




    const [designationtype, setdesignationtype] = useState([
        {
            name: "General"
        },
        {
            name: "Driver"
        },
    ]);


    const fileInput = useRef();
    const auth = getAuth();

    const signuphandler = async () => {
        // if (picture == "") {
        //     toast.warn("Please select a profile picture")
        //     return
        // } else
        if (firstname == "") {
            toast.warn("Please enter your first name")
            return
        } else if (lastname == "") {
            toast.warn("Please enter your last name")
            return
        } else if (email == "") {
            toast.warn("Please enter your email address")
            return
        } else if (phonenumber == "") {
            toast.warn("Please enter your phone number")
            return
        } else if(selectedproject.length > 0 && selectedproject.find(data => data.hourlywage == "") != undefined || selectedproject.length > 0 && selectedproject.find(data => data.hourlywage == 0) != undefined){
            toast.warn("You must enter a valid hourly wage for all projects assigned")
            return
        }

        // } else if (password == "") {
        //     toast.warn("Please enter your password")
        //     return
        // } else if (confirmpassword == "") {
        //     toast.warn("Please enter your password")
        //     return
        // } else if (password != confirmpassword) {
        //     toast.warn("Passwords do not match")
        //     return
        // }

        else {

            setloader(true)

            let employeeid = 0


            await gethighestWmployeeID("users", "employeeid", "desc", user?.id).then(async (response) => {

            if(response.length == 0){
                employeeid = 1
            }else{
                employeeid =  parseInt(response[0].employeeid) + 1
            }


            const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
            let password = '';

            for (let i = 0; i < 8; i++) {
              const randomIndex = Math.floor(Math.random() * charset.length);
              password += charset[randomIndex];
            }

            const res = await fetch('https://us-central1-raftekpro-82bb0.cloudfunctions.net/api/createaccount', {
                // const res = await fetch('http://localhost:3002/createaccount', {

                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  email: email,
                  password: password
                })
              });
        if(res.status == 200){
        const dataresponse = await res.json();

        let projectsassigned = []
        for (const keyindex in selectedproject){
            projectsassigned.push({
                project: selectedproject[keyindex].id,
                hourlywage: selectedproject[keyindex].hourlywage

            })
        }
            if (picture == "") {



                let data = {
                    id:   dataresponse.data.uid,
                    employeeid: employeeid,
                    firstname: firstname,
                    createdby: user?.id,
                    lastname: lastname,
                    email: email,
                    phonenumber: phonenumber,
                    profilepicture: "https://firebasestorage.googleapis.com/v0/b/raftekpro-82bb0.appspot.com/o/profilepicture%2F1726660552437-image_2024_09_18T11_54_21_012Z.png?alt=media&token=528ec161-9338-414d-9c14-b8078ae263fc",
                    designation: usertype == "Supervisor" ? "Supervisor" : usertype != "Admin" && usertype != "Supervisor" ? designation : "",
                    usertype: usertype,
                    leaves: 0,
                    projectassigned: projectsassigned

                }

if( usertype == "Admin" || usertype == "SuperAdmin" ){
data["deleteaccess"] = true
data["editaccess"] = true
data["scheduleaccess"] = true

}else if(usertype == "Supervisor"){
data["deleteaccess"] = false
data["editaccess"] = false
data["employeerole"] = false
data["scheduleaccess"] = false
}

                await saveuserdata(data,  dataresponse.data.uid).then(async (response) => {
                    if (response == "success") {

                        for (const indexdata in selectedproject){


                                let dataarray = {
                                    employees: arrayUnion(dataresponse.data.uid)
                                }

                                await updateItem("users", selectedproject[indexdata].id, dataarray).then(async (response) => {



            if(indexdata   == selectedproject.length - 1){
            toast.success("Sign Up Successful")
            setselectedproject([])
            setpicture("")
            setfirstname("")
            setlastname("")
            setemail("")
            setphonenumber("")
            setpassword("")
            setconfirmpassword("")
            }

                                }).catch((err) => {
                                    alert("updating error " + err)
                                    setloader(false)

                                })

                        }


                    } else {
                        toast.error(response)
                    }
                    setloader(false)
                })


            } else {


                //////////////////////////////////////////
                const imageFile = picturedata.target.files[0]
                const storageRef = ref(storage, `profilepicture/${Date.now()}-${imageFile.name}`)
                const uploadTask = uploadBytesResumable(storageRef, imageFile)
                uploadTask.on('state_changed', (snapshot) => {
                    //   const uploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
                }, (error) => {
                    setloader(false)
                    toast.error("upload error " + error.message);
                }, () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {
                        let data = {
                            id:  dataresponse.data.uid,
                            employeeid: employeeid,
                            createdby: user?.id,
                            firstname: firstname,
                            lastname: lastname,
                            email: email,
                            phonenumber: phonenumber,
                            profilepicture: downloadURL,
                            designation: usertype == "Supervisor" ? "Supervisor" : usertype != "Admin" && usertype != "Supervisor" ? designation : "",
                            usertype: usertype,
                            leaves: 0,
                            projectassigned: projectsassigned

                        }
                        await saveuserdata(data,  dataresponse.data.uid).then(async(response) => {
                            if (response == "success") {
                                for (const indexdata in selectedproject){


                                    let dataarray = {
                                        employees: arrayUnion(dataresponse.data.uid)
                                    }

                                    await updateItem("users", selectedproject[indexdata].id, dataarray).then(async (response) => {


                if(indexdata   == selectedproject.length - 1){
                toast.success("Sign Up Successful")
                setselectedproject([])
                setpicture("")
                setfirstname("")
                setlastname("")
                setemail("")
                setphonenumber("")
                setpassword("")
                setconfirmpassword("")
                }

                                    }).catch((err) => {
                                        alert("updating error " + err)
                                        setloader(false)

                                    })

                            }
                            } else {
                                toast.error(response)
                            }
                            setloader(false)
                        })
                    })
                        .catch((error) => {
                            setloader(false)
                            toast.error(error.message);
                        });
                })
            }


            const templateParams = {
                email: email,
                message: `email: ${email} password: ${password}`,
              };

              emailjs.send(
                'service_5z77uee',      // Replace with your Service ID
                'template_qncqtt5',     // Replace with your Template ID
                templateParams,
                'pUoX1kN2nzw7N8UsM'          // Replace with your User ID
              )
              .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
              })
              .catch((err) => {
                console.error('FAILED...', err);
              });


        }else{
let error = await res.json()
        toast.error(error.error)
        setloader(false)
        }





            }).catch((err) => {
                console.log(err)
                setloader(false)
              })
        }
    }

    const handlehourlywage = (event, index) => {

        let data = [...selectedproject]
        data[index].hourlywage = event.target.value
        setselectedproject(data)
      }
    const handlefixedprice = (event) => {
        setfixedprice(event.target.value)
      }
    const initialiseusertypes = () => {
        if (user?.usertype == "SuperAdmin") {
            setusertypelist([
                {
                    name: "Employee"
                },
                {
                    name: "Admin"
                },
                {
                    name: "Supervisor"
                }

            ])
        } else {
            setusertypelist([
                {
                    name: "Employee"
                },

                {
                    name: "Supervisor"
                }

            ])
        }
    }


    const handleemailChange = (event) => {
        setemail(event.target.value)

    }

    const handlepasswordChange = (event) => {
        setpassword(event.target.value)

    }
    const handleconfirmpasswordChange = (event) => {
        setconfirmpassword(event.target.value)

    }

    const handlephonenumberChange = (event) => {
        setphonenumber(event.target.value)

    }

    const handlefirstnameChange = (event) => {
        setfirstname(event.target.value)

    }

    const handlelastnameChange = (event) => {
        setlastname(event.target.value)
    }


    const handleimageChange = (event) => {
        fileInput.current.click();
    }


    const handleimageselected = (e) => {
        setpicturedata(e)
        setpicture(URL.createObjectURL(e.target.files[0]));
    }


    return (
        <div className='w-[100%] lg:flex  items-center  flex-col  mt-[70px] md:mt-[0px] '>
            <p className='text-[22px] text-textcolor PoppinsRegular font-semibold self-start '>User Registration</p>
            <div className='w-full  flex items-center justify-center mt-[20px]'>
                <div className='w-[100%] lg:w-[100%] mx-auto '>
                    <div className='bg-white py-3 px-7 border border-gray-300 rounded-xl bg-cardOverlay  drop-shadow-lg'>
                        <div className='flex flex-col lg:flex-row justify-start lg:justify-between pt-3 lg:items-center'>

                            <div className='flex flex-row items-center mt-5 lg:mt-0'>
                                <div className='relative' onClick={() => {
                                    setusertypepopup(!usertypepopup)
                                    setdesignationtypepopup(false)
                                }}>
                                    <p className='text-textcolor PoppinsRegular font-medium cursor-pointer'>{usertype == "Admin" ? "Client" : usertype}</p>
                                    {usertypepopup &&
                                        <div
                                        ref = {usertypepopupref}
                                        className='w-[150px] bg-white rounded-xl border border-gray-300 flex items-center flex-col p-[5px] absolute z-10 right-[-60px] lg:left-[0px] top-[30px]'>
                                            {usertypelist.map((item, index) => (

                                                <div
                                                key = {index.toString()}
                                                onClick={() => {
                                                    setusertype(item.name)
                                                    setusertypepopup(!usertypepopup)
                                                }}
                                                    className='hover:bg-themeColor hover:rounded-xl w-full text-center py-2 cursor-pointer text-textcolor hover:text-white'>
                                                    <p className='PoppinsRegular font-medium'>{item.name == "Admin" ? "Client" : item.name}</p>
                                                </div>
                                            ))}
                                        </div>}
                                </div>
                                {usertype != "Admin" && usertype != "Supervisor" &&
                                    <div
                                        className='ml-[10px] relative'
                                        onClick={() => {
                                            setusertypepopup(false)
                                            setdesignationtypepopup(!designationtypepopup)
                                        }}>
                                        <p className='text-textcolor PoppinsRegular font-medium cursor-pointer'>{designation}</p>
                                        {designationtypepopup &&
                                            <div
                                            ref ={designationtypepopupref}
                                            className='flex  bg-white rounded-md flex-col z-10 left-[0px] top-[30px] border border-gray-300 p-[5px] absolute'>
                                                {designationtype.map((item, index) => (
                                                    <div
                                                    key = {index.toString()}
                                                    onClick={() => {
                                                        setdesignation(item.name)
                                                        setdesignationtypepopup(!designationtypepopup)
                                                    }}
                                                        className='hover:bg-themeColor hover:rounded-xl w-full text-center py-2 cursor-pointer text-textcolor hover:text-white px-[50px]'>
                                                        <p className=' font-medium PoppinsRegular text-sm '>{item.name}</p>
                                                    </div>
                                                ))}
                                            </div>}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='w-full flex justify-center'>
                            <div
                                className='flex justify-center my-[10px] border border-white rounded-full items-center relative mt-5 lg:mt-0'

                            >
                                {picture ? <img src={picture} className='w-[110px] h-[110px] object-cover rounded-full bg-cardOverlay  drop-shadow-lg' /> : <FaUser

                                    className="text-lighttextGray/80 p-4 border border-gray-300 rounded-full w-[110px] h-[110px] bg-cardOverlay  drop-shadow-lg bg-white"
                                />}
                                <input type="file" style={{ "display": "none" }} ref={fileInput} onChange={handleimageselected} />
                                <div
                                    onClick={handleimageChange}

                                    className='absolute bg-gray-300 w-[30px] h-[30px] flex justify-center items-center rounded-full right-[-10px] top-[70px] cursor-pointer'>
                                    <MdEdit

                                        className="text-textcolor w-[20px] h-[20px]"
                                    />
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-col lg:flex-row justify-center lg:justify-between mt-[20px]'>
                            <div className='w-full lg:w-[48%]' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>First name</p>
                                <input type='text'
                                    value={firstname} placeholder='First name'
                                    onChange={handlefirstnameChange}
                                    className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                            </div>


                            <div className='w-full lg:w-[48%] mt-4 lg:mt-0' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Last name</p>
                                <input type='text'
                                    value={lastname} placeholder='Last name'
                                    onChange={handlelastnameChange}
                                    className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                            </div>
                        </div>


                        <div className='flex flex-col lg:flex-row justify-center lg:justify-between mt-[20px]'>
                            <div className='w-full lg:w-[48%]' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Email</p>
                                <input type='text'
                                    value={email} placeholder='Email'
                                    onChange={handleemailChange}
                                    className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                            </div>
                            <div className='w-full lg:w-[48%] mt-4 lg:mt-0' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Phone Number</p>
                                <input
                                    value={phonenumber} placeholder='Phone Number'
                                    onChange={handlephonenumberChange}
                                    type="tel" className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                            </div>
                        </div>
                        {/* <div className='w-full mt-[20px]' >
                        <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Hourly Wage</p>
          <div className='w-full py-[6px] rounded-xl border border-themeColor items-center flex flex-row'>
            <div className='pl-5'>
              <FaDollarSign />
            </div>
            <input type='text'
              inputMode='numeric'
              value={fixedprice}
              placeholder={'Price'}
              onChange={handlefixedprice}
              className="p-[0px] text-textcolor text-sm font-medium PoppinsRegular  w-[95%] lg:w-[97%]  px-[17px] lg:px-4 border-none items-center flex"
            />

            <p className='text-textcolor  text-lg pr-2 '>/hr</p>
          </div>
        </div> */}
                        {/* <div className='flex flex-col lg:flex-row justify-center lg:justify-between mt-[20px]'>
                            <div className='w-full relative lg:w-[48%]' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Password</p>
                                <input
                                    value={password} placeholder='Enter password'
                                    onChange={handlepasswordChange}
                                    type={passwordvisible ? "text" : "password"} className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                                <div
                                                                    onClick={()=>{setpasswordvisible(!passwordvisible)}}
                                className='absolute top-[37px] right-[10px]'>
                                {passwordvisible ?
                                <IoMdEye

className="text-themeColor w-[25px] h-[25px]"
/>
:
<IoMdEyeOff

className="text-themeColor w-[25px] h-[25px]"
/>

    }

                                </div>
                            </div>

                            <div className='w-full relative lg:w-[48%] mt-4 lg:mt-0'>
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Confirm password</p>
                                <input
                                    value={confirmpassword} placeholder='Confirm password'
                                    onChange={handleconfirmpasswordChange}
                                    type={confirmpasswordvisible ? "text" : "password"}  className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                                    <div
                                    onClick={()=>{setconfirmpasswordvisible(!confirmpasswordvisible)}}
                                    className='absolute top-[37px] right-[10px]'>
                                    {confirmpasswordvisible ?
                                <IoMdEye

className="text-themeColor w-[25px] h-[25px]"
/>
:
<IoMdEyeOff

className="text-themeColor w-[25px] h-[25px]"
/>

    }

                                </div>
                            </div>
                        </div> */}




                        <div className="w-full h-[1px] mt-6 bg-bordercontainer"></div>

                    </div>
                </div>
            </div>


{usertype == "Employee" || usertype == "Supervisor" ?
            <div className='w-full  flex items-center justify-center mt-[20px] '>
                <div className='w-[100%] lg:w-[100%] mx-auto '>
                    <div className='bg-white py-[30px] px-7 border border-gray-300 rounded-xl bg-cardOverlay  drop-shadow-lg flex flex-col items-center justify-center  '>

                    <div className='w-full md:w-[45%] mt-[40px] md:mt-[0px] py-[20px] '>
                        <div

                            className='w-full flex flex-col items-center ' >


                            {selectedproject.length > 0 ?
                                <div className='flex flex-row' >
                                    {selectedproject.map((item, ind) => {
                                        return (
                                            <img
                                                style={{
                                                    marginLeft: selectedproject.length > 1 ? -50 : 0,
                                                }}
                                                src={item.logo ? item.logo : userlogo} className='w-[100px] h-[100px] rounded-full object-cover' />
                                        )
                                    })}
                                </div>
                                :
                                <img

                                    src={userlogo} className='w-[100px] h-[100px] rounded-full object-cover' />
                            }
                            <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1 w-full text-left mt-[20px]'>Select Project</p>
                            <div

                            className='flex relative  w-full'>
                            <div
        onClick={() =>{ setprojectpopup(!projectpopup)}}
                                className={`relative px-4  text-gray-500 text-sm font-medium PoppinsRegular w-full py-3 rounded-md border border-themeColor items-center flex `}>
                                    {selectedproject?.length == 0 ? "Select Employee" : selectedproject.map(function (data, index) {
                                        return <div
                                            // onClick={() => {

                                            //     let dataset = [...selectedproject]

                                            //     dataset = dataset.filter((itemdata, indexdata) => itemdata.id != data.id);
                                            //     setselectedproject(dataset)


                                            // }}
                                            className='bg-themeColor px-[5px] py-[1px] mr-[5px] rounded-md flex flex-row items-center mb-[2px]'><p className='text-white '>{data.title}</p>
                                            {/* <IoIosCloseCircle
                                                onClick={(id) => {
                                                    console.log(id)
                                                }}
                                                className=" text-white ml-[3px]  w-[15px] h-[15px] hover:cursor-pointer"
                                            /> */}
                                        </div>
                                    })}


                            </div>
                            <ProjectPopUp
                                        visible ={projectpopup}
                                        data = {clients}
                                        selectedproject = {selectedproject}
                                        activateoutsidecontainerclick = {()=>{
                                            setprojectpopup(false);
                                        }}
                                        onClickincorrect={() => { setselectedproject([]) }}
                                                   onClickcorrect={(item) => {

                                                   if(selectedproject.find(data => data.id == item.id) == undefined){
                                                    let newitem = {...item}
                                                    newitem["hourlywage"] = 0
                                                    let dataset = [...selectedproject]
                                                    dataset.push(newitem)
                                                    setselectedproject(dataset)


                                                   }else{

                                                    let dataset = [...selectedproject]

                                                    dataset = dataset.filter((itemdata, indexdata) => itemdata.id != item.id);
                                                    setselectedproject(dataset)
                                                   }
                                                    }}
                                      />

                            </div>


                        </div>
                    </div>


                    {selectedproject.map(function (data, index) {
                                        return(
<div className='w-full flex flex-row items-center justify-center'>
    <div className='mr-[20px]'>
<FaCircleMinus
onClick={()=>{
    let dataset = [...selectedproject]
    dataset = dataset.filter((itemdata, indexdata) => itemdata.id != data.id);
    setselectedproject(dataset)
}}
className=" text-red-500 w-[20px] h-[20px] hover:cursor-pointer mt-[20px]"
/>
</div>
<div className='w-full' >
<p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Project</p>
<div className=' input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex' >{data.title}</div>
</div>
<div className='w-full md:w-[30%] ml-[20px] ' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Hourly Wage</p>
                                <div className='w-full py-[6px] rounded-xl border border-themeColor items-center flex flex-row'>
            <div className='pl-5'>
              <FaDollarSign />
            </div>
            <input type="number"
              inputMode='numeric'
              value={data.hourlywage}
              placeholder={'Salary'}
              onChange={(event)=>{handlehourlywage(event, index)}}
              className="p-[0px] text-textcolor text-sm font-medium PoppinsRegular  w-[95%] lg:w-[97%]  px-[17px] lg:px-4 border-none items-center flex"
            />

            <p className='text-textcolor  text-lg pr-2 '>/hr</p>
          </div>
                            </div>
</div>
                                        )})}

                </div>
</div>

            </div> : null}


            <div className='w-full lg:w-[100%] mx-auto  py-[30px]'>
                            {loader ?
                                <div
                                className="text-center py-4  mt-6 w-full flex justify-center"
                                >
                                    <RotatingLines
                                        visible={true}
                                        height="25"
                                        width="25"
                                        strokeColor="#218fdb"

                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        ariaLabel="rotating-lines-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </div>
                                :
                                <button type='submit' className="text-center py-4 rounded-2xl  bg-themeColor hover:bg-themeColor/80 w-full text-white text-sm lg:text-base font-semibold PoppinsRegular uppercase" onClick={() => signuphandler()}>Create</button>
                            }
                        </div>
        </div>
    )
}

export default CreateEmployee;