import React, { useState, useEffect, useRef } from 'react';
import './HorizontalDatePicker.css';

const HorizontalDatePicker = ({ startDate, endDate, selectedDate: initialSelectedDate, onDateSelected }) => {
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate || new Date());
  const [dates, setDates] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(selectedDate.getMonth());
  const [currentYear, setCurrentYear] = useState(selectedDate.getFullYear());
  const datePickerRef = useRef(null);
  const dateRefs = useRef([]);

  // Generate dates for the selected month and year
  useEffect(() => {
    const newDates = [];
    const date = new Date(currentYear, currentMonth, 1);
    while (date.getMonth() === currentMonth) {
      if (date >= startDate && date <= endDate) {
        newDates.push(new Date(date));
      }
      date.setDate(date.getDate() + 1);
    }
    setDates(newDates);
  }, [currentMonth, currentYear, startDate, endDate]);

  // Scroll to selected date on first render
  useEffect(() => {
    if (datePickerRef.current && selectedDate && dateRefs.current.length > 0) {
      const index = dates.findIndex((date) => date.toDateString() === selectedDate.toDateString());
      if (index !== -1 && dateRefs.current[index]) {
        const selectedDateElement = dateRefs.current[index];
        const offsetLeft = selectedDateElement.offsetLeft;
        const containerWidth = datePickerRef.current.offsetWidth;
        const elementWidth = selectedDateElement.offsetWidth;

        datePickerRef.current.scrollTo({
          left: offsetLeft - containerWidth / 2 + elementWidth / 2,
          behavior: 'smooth',
        });
      }
    }
  }, [dates, selectedDate]);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    onDateSelected && onDateSelected(date);
  };

  const handleMonthChange = (event) => {
    const [year, month] = event.target.value.split('-');
    setCurrentYear(parseInt(year, 10));
    setCurrentMonth(parseInt(month, 10));
  };

  const handleScroll = () => {
    if (dateRefs.current.length > 0) {
      const containerCenter = datePickerRef.current.scrollLeft + datePickerRef.current.offsetWidth / 2;
      let closestDate = dates[0];
      let minDistance = Math.abs(containerCenter - dateRefs.current[0].offsetLeft);

      dateRefs.current.forEach((dateRef, index) => {
        if (dateRef) {
          const distance = Math.abs(containerCenter - (dateRef.offsetLeft + dateRef.offsetWidth / 2));
          if (distance < minDistance) {
            minDistance = distance;
            closestDate = dates[index];
          }
        }
      });

      if (closestDate) {
        setCurrentMonth(closestDate.getMonth());
        setCurrentYear(closestDate.getFullYear());
      }
    }
  };

  return (
    <div className="date-picker-container">
      <div className="sticky-header">
        <select value={`${currentYear}-${currentMonth}`} onChange={handleMonthChange}>
          {generateMonthOptions(startDate, endDate)}
        </select>
      </div>

      <div className="horizontal-date-picker" ref={datePickerRef} onScroll={handleScroll}>
        {dates.map((date, index) => {
          const isSelected = selectedDate && selectedDate.toDateString() === date.toDateString();
          const dayName = date.toLocaleDateString('default', { weekday: 'short' });
          const dayNumber = date.getDate();

          return (
            <div
              key={date.toISOString()}
              ref={(el) => (dateRefs.current[index] = el)}
              className={`date-item ${isSelected ? 'selected' : ''}`}
              onClick={() => handleDateClick(date)}
            >
              <div className="day-name">{dayName}</div>
              <div className="day-number">{dayNumber}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const generateMonthOptions = (startDate, endDate) => {
  const options = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const label = currentDate.toLocaleDateString('default', { month: 'long', year: 'numeric' });

    options.push(
      <option key={`${year}-${month}`} value={`${year}-${month}`}>
        {label}
      </option>
    );

    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return options;
};

export default HorizontalDatePicker;